<template>
  <section>
    <b-card>
      <!-- <h3>{{ secCode }}</h3> -->
      <!-- <b-btn size="sm" @click="loadFincancial()" v-if="$can('create', 'FinStatement')"
        >Скачать фин. отчетность</b-btn
      > -->
    </b-card>
  </section>
</template>

<script>
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import { loadFinancialStatement } from '@/api/investments';

export default {
  name: 'Security',
  setup(props, context) {
    const toast = useToast();
    const secCode = context.root.$route.params.code;

    const loadFincancial = async () => {
      try {
        const tickers = [];
        tickers.push(secCode);

        await loadFinancialStatement({ tickers });
        toast({
          component: ToastificationContent,
          props: {
            title: 'Выполнено',
            icon: 'ThumbsUpIcon',
            variant: 'success',
            text: 'Загрузка выполнена',
          },
        });
      } catch (err) {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка загрузки данных',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Перезагрузите страницу',
          },
        });
        throw err;
      }
    };

    return {
      loadFincancial,
    };
  },
};
</script>

<style></style>
